// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.route-history {
    background-color: #f4f7fa;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 12000px;
    margin: 20px auto;
  }
  
  .route-history h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #1a1a2e;
  }
  
  .route-history-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(25% - 20px);
  }
  
  .form-group input {
    flex: 1 1;
    border: none;
    outline: none;
    padding: 5px;
    margin-left: 10px;
    font-size: 14px;
  }
  
  .form-group input::placeholder {
    color: #999;
  }
  
  .map-container {
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/RouteHistory.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B;EAC5B;;EAEA;IACE,SAAO;IACP,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,gBAAgB;EAClB","sourcesContent":[".route-history {\n    background-color: #f4f7fa;\n    border-radius: 10px;\n    padding: 20px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    max-width: 12000px;\n    margin: 20px auto;\n  }\n  \n  .route-history h2 {\n    text-align: center;\n    margin-bottom: 20px;\n    color: #1a1a2e;\n  }\n  \n  .route-history-form {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    gap: 10px;\n    margin-bottom: 20px;\n  }\n  \n  .form-group {\n    display: flex;\n    align-items: center;\n    background-color: white;\n    padding: 10px;\n    border-radius: 5px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    flex: 1 1 calc(25% - 20px);\n  }\n  \n  .form-group input {\n    flex: 1;\n    border: none;\n    outline: none;\n    padding: 5px;\n    margin-left: 10px;\n    font-size: 14px;\n  }\n  \n  .form-group input::placeholder {\n    color: #999;\n  }\n  \n  .map-container {\n    height: 400px;\n    border-radius: 10px;\n    overflow: hidden;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
