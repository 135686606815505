// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aumodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.aumodal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
}

.aumodal-content h2 {
  margin-top: 0;
}

.aumodal-content label {
  display: block;
  margin-bottom: 10px;
}

.aumodal-content input,
.aumodal-content select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.aumodal-actions {
  display: flex;
  justify-content: space-between;
}

.aumodal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.aumodal-actions button:first-child {
  background-color: #4caf50;
  color: white;
}

.aumodal-actions button:last-child {
  background-color: #f44336;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/AddDronePopup.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".aumodal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.aumodal-content {\n  background-color: white;\n  padding: 20px;\n  border-radius: 5px;\n  width: 500px;\n}\n\n.aumodal-content h2 {\n  margin-top: 0;\n}\n\n.aumodal-content label {\n  display: block;\n  margin-bottom: 10px;\n}\n\n.aumodal-content input,\n.aumodal-content select {\n  width: 100%;\n  padding: 8px;\n  margin-top: 5px;\n  margin-bottom: 15px;\n}\n\n.aumodal-actions {\n  display: flex;\n  justify-content: space-between;\n}\n\n.aumodal-actions button {\n  padding: 10px 20px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.aumodal-actions button:first-child {\n  background-color: #4caf50;\n  color: white;\n}\n\n.aumodal-actions button:last-child {\n  background-color: #f44336;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
