// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  
}

.sidebar {
  z-index: 1000; /* Ensure sidebar stays on top */
}

.main-content {
  margin-left: 80px;
  padding: 20px;
  width: calc(100% - 80px);
  transition: margin-left 0.3s, width 0.3s;
}

.topbar {
  position: fixed;
  top: 0;
  left: 80px;
  width: calc(100% - 80px);
  height: 60px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  transition: left 0.3s, width 0.3s;
  z-index: 999; /* Ensure topbar stays on top */
}

.content-wrapper {
  margin-top: 50px;
  width: 100% !important;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.sidebar-footer li {
  padding: 10px 0;
}

.sidebar-footer li i {
  font-size: 20px;
}
.blinking {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;EACE,aAAa,EAAE,gCAAgC;AACjD;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,wBAAwB;EACxB,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,MAAM;EACN,UAAU;EACV,wBAAwB;EACxB,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,iCAAiC;EACjC,YAAY,EAAE,+BAA+B;AAC/C;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".app {\n  \n}\n\n.sidebar {\n  z-index: 1000; /* Ensure sidebar stays on top */\n}\n\n.main-content {\n  margin-left: 80px;\n  padding: 20px;\n  width: calc(100% - 80px);\n  transition: margin-left 0.3s, width 0.3s;\n}\n\n.topbar {\n  position: fixed;\n  top: 0;\n  left: 80px;\n  width: calc(100% - 80px);\n  height: 60px;\n  background-color: #ffffff;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 20px;\n  transition: left 0.3s, width 0.3s;\n  z-index: 999; /* Ensure topbar stays on top */\n}\n\n.content-wrapper {\n  margin-top: 50px;\n  width: 100% !important;\n}\n\n.sidebar-footer {\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 20px;\n}\n\n.sidebar-footer li {\n  padding: 10px 0;\n}\n\n.sidebar-footer li i {\n  font-size: 20px;\n}\n.blinking {\n  animation: blink 1s infinite;\n}\n\n@keyframes blink {\n  0%, 50%, 100% {\n    opacity: 1;\n  }\n  25%, 75% {\n    opacity: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
