// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tiles-component {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: space-between;
}

.tile {
  background-color: white;
  border: 2px solid;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(16.666% - 20px); /* Ensure tiles are flexible and equally spaced */
  min-width: 200px; /* Ensure a minimum width for responsiveness */
  transition: transform 0.3s, box-shadow 0.3s;
}

.tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.tile-content {
  display: flex;
  align-items: center;
}

.tile-icon {
  font-size: 2.5em;
  margin-right: 15px;
}

.tile-info h3 {
  margin: 0;
  font-size: 1.5em;
  color: #333;
}

.tile-info p {
  margin: 0;
  color: #777;
  font-size: 1em;
}

@media (max-width: 768px) {
  .tile {
    flex: 1 1 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/TilesComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,wCAAwC;EACxC,aAAa;EACb,mBAAmB;EACnB,YAA8B;EAA9B,cAA8B;EAA9B,gCAA8B,EAAE,iDAAiD;EACjF,gBAAgB,EAAE,8CAA8C;EAChE,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;EAC3B,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,SAAS;EACT,WAAW;EACX,cAAc;AAChB;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".tiles-component {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  padding: 20px;\n  justify-content: space-between;\n}\n\n.tile {\n  background-color: white;\n  border: 2px solid;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  display: flex;\n  align-items: center;\n  flex: 1 1 calc(16.666% - 20px); /* Ensure tiles are flexible and equally spaced */\n  min-width: 200px; /* Ensure a minimum width for responsiveness */\n  transition: transform 0.3s, box-shadow 0.3s;\n}\n\n.tile:hover {\n  transform: translateY(-5px);\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n}\n\n.tile-content {\n  display: flex;\n  align-items: center;\n}\n\n.tile-icon {\n  font-size: 2.5em;\n  margin-right: 15px;\n}\n\n.tile-info h3 {\n  margin: 0;\n  font-size: 1.5em;\n  color: #333;\n}\n\n.tile-info p {\n  margin: 0;\n  color: #777;\n  font-size: 1em;\n}\n\n@media (max-width: 768px) {\n  .tile {\n    flex: 1 1 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
