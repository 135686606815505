// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Topbar.css */

.topbar {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 80px;
  width: calc(100% - 80px);
  height: 100px;
  z-index: 999;
}

.topbar.expanded {
  left: 200px;
  width: calc(100% - 200px);
}

.logo-container {
  background-color: #f0f0f0;  /* Adjust to your desired background color */
  padding: 5px;
  border-radius: 5px; /* Optional: adds rounded corners */
}

.logo {
  height: 40px;
}




`, "",{"version":3,"sources":["webpack://./src/components/Topbar.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,wCAAwC;EACxC,eAAe;EACf,MAAM;EACN,UAAU;EACV,wBAAwB;EACxB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB,GAAG,4CAA4C;EACxE,YAAY;EACZ,kBAAkB,EAAE,mCAAmC;AACzD;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* Topbar.css */\n\n.topbar {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  background-color: #fff;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  position: fixed;\n  top: 0;\n  left: 80px;\n  width: calc(100% - 80px);\n  height: 100px;\n  z-index: 999;\n}\n\n.topbar.expanded {\n  left: 200px;\n  width: calc(100% - 200px);\n}\n\n.logo-container {\n  background-color: #f0f0f0;  /* Adjust to your desired background color */\n  padding: 5px;\n  border-radius: 5px; /* Optional: adds rounded corners */\n}\n\n.logo {\n  height: 40px;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
