// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-nav {
    display: flex;
    justify-content: flex-start;
    background-color: #1a1a2e;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .nav-item {
    color: white;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .nav-item.active {
    border-bottom: 2px solid #4caf50;
  }
  
  .nav-item i {
    margin-left: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TopNav.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,yBAAyB;IACzB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,gCAAgC;EAClC;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".top-nav {\n    display: flex;\n    justify-content: flex-start;\n    background-color: #1a1a2e;\n    padding: 10px;\n    margin-bottom: 20px;\n  }\n  \n  .nav-item {\n    color: white;\n    padding: 10px 20px;\n    margin-right: 10px;\n    cursor: pointer;\n    font-size: 18px;\n  }\n  \n  .nav-item.active {\n    border-bottom: 2px solid #4caf50;\n  }\n  \n  .nav-item i {\n    margin-left: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
