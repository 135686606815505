// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-component {
    height: 600px;
    background-color: #e0e0e0;
    position: relative;
    width:100% !important;
  }
  
  .map-placeholder {
    height: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .map-marker {
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MapComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,oCAAoC;EACtC","sourcesContent":[".map-component {\n    height: 600px;\n    background-color: #e0e0e0;\n    position: relative;\n    width:100% !important;\n  }\n  \n  .map-placeholder {\n    height: 150%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .map-marker {\n    background-color: white;\n    border: 1px solid #ccc;\n    padding: 10px;\n    margin: 5px;\n    border-radius: 5px;\n    box-shadow: 0 0 10px rgba(0,0,0,0.1);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
